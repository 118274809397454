.form {
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		border: 1px solid var(--Grayscale-Gray-30);
		-webkit-text-fill-color: var(--Grayscale-White);
		-webkit-box-shadow: 0 0 0px 1000px #000 inset;
		transition: background-color 5000s ease-in-out 0s;
	}
	&--type2 {
		.form-body {
			width: 100%;
			max-width: 660px;
			border-radius: 10px;
			box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);

			background: var(--Grayscale-Gray-80);
			margin: 0;
			padding: 20px;
		}

		.input--success .input-item {
			border: 1px solid var(--additional-white);
		}

		.input__name {
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
			margin-bottom: 8px;
		}

		.input-item {
			height: 44px;
			border-radius: 4px;
			background: var(--Grayscale-Gray-100);
			border: 1px solid var(--Grayscale-Gray-100);
			padding: 0 16px;
			color: var(--Grayscale-White);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */

			&::placeholder {
				color: var(--Grayscale-Gray-20);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				opacity: 1;
			}
		}
		.select-input {
			&::placeholder {
				color: var(--Grayscale-Gray-20);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				opacity: 1;
			}
		}
		.select {
			height: 44px;

			.select__custom {
				background: var(--Grayscale-Gray-100);

				span {
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
				}
			}
		}
		.select-block__name {
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%;
			margin-bottom: 8px;
		}

		.coin--type3 .coin__text {
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			padding: 0;
		}
		.select__drop {
			background-color: var(--Grayscale-Gray-90);
			padding: 8px;
			button {
				border-bottom: 1px solid var(--Grayscale-Gray-70);
			}
		}
	}
	&--type3 {
		border-radius: 6px;
		background: var(--Grayscale-Gray-80);
		padding: 24px;
		color: var(--Grayscale-White);

		.form-body {
			width: 100%;
			margin: 0;
		}

		.input {
			margin-bottom: 0;

			&--grid {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 16px;
			}
		}

		.input__name {
			color: var(--Grayscale-Gray-20);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}

		.input-item {
			border-radius: 4px;
			border: 1px solid var(--Grayscale-Gray-80);
			background: var(--Grayscale-Gray-100);
			height: 48px;
			padding: 12px 16px;
			color: var(--Grayscale-White);
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */

			&::placeholder {
				font-family: var(--font-sans);
				color: var(--Grayscale-Gray-20);

				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				opacity: 1;
			}
		}

		.textarea-item {
			height: 100px;
		}

		.select {
			width: 100%;
			height: 44px;
			border: none;
			border-radius: 4px;

			@media screen and (max-width: 768px) {
				width: 100%;
			}

			.select__custom {
				font-weight: 500;
				font-size: 14px;
				font-family: var(--font-sans);

				.select-value {
					overflow: visible;
				}

				.upper__text {
					font-weight: 500;
					font-family: var(--font-sans);
					color: var(--gray800);
				}
			}
		}

		.form__divider {
			margin: 18px 0;
		}

		.text-center {
			color: var(--Grayscale-White);
		}
	}

	textarea.input-item.input-item--floating {
		padding: 11px 0;
		min-height: 48px;
		resize: vertical;

		/*&:focus + label,
		&:not(:placeholder-shown) + label {
			top: -14px;
		}

		+ label {
			top: 0;
		}*/
	}

	.input--error .input-item,
	.input--error .textarea {
		border: 1px solid var(--additional-error);
		color: var(--Grayscale-White);
	}

	.input--error .input-item.input-item--floating {
		border: 0;
		border-bottom: 1px solid var(--additional-error);
	}

	.input--success .input-item {
		border: 1px solid var(--Grayscale-Gray-80);
	}

	.input--success .input-item.input-item--floating {
		border: 0;
		border-bottom: 1px solid var(--Grayscale-Gray-10);
	}

	.input-wrap {
		&--col-2 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 10px;
		}
	}

	.input-notify__text {
		display: block;
		color: var(--additional-error);

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
